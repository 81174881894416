import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SubscriptionComponent } from './shared/subscription/subscription.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

export const appRoutes: Routes = [
  {
    path: 'subscription/:token/:subscription_status',
    component: SubscriptionComponent,
    data: { title: 'Vioniko: Subscription Opt In/Out'},
  },
  {
    path: 'template',
    loadChildren: () => import('./template/template.module').then((m) => m.TemplateModule)
  },
  {
    path: '**',
    loadChildren: () => import('./template/template.module').then((m) => m.TemplateModule)
    // component: PageNotFoundComponent,
    // data: { title: 'Vioniko: Page Not Found'},
  }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  PageNotFoundComponent
];
