import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { mergeMap, map, filter, delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  showHeader: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
    ){

  }
  async ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while(route.firstChild) route = route.firstChild;
          return route
        }),
        filter((route) => route.outlet == 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => this.titleService.setTitle(event['title']));
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {

  }

}
