import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpClientJsonpModule } from '@angular/common/http';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { TemplateService } from './template/template.service';
// import { BadgeTemplateService } from './badge/badge-template.service';

import { AppComponent } from './app.component';
import { NavigationModule } from './navigation/navigation.module';
import { AlertComponent } from './shared/alerts/alert.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AlertService } from './shared/alerts/alert.service';
import { SubscriptionComponent } from './shared/subscription/subscription.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    routingComponents,
    SubscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NavigationModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    // FontAwesomeModule
  ],
  providers: [
    AlertService,
    // TemplateService,
    // BadgeTemplateService
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertComponent],
  exports: [
    HttpClientModule,
    FormsModule
  ]
})
export class AppModule { }
