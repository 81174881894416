import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from './alert.service';

@Component({
  moduleId: module.id,
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  messages: any = [];

  constructor(private alertService: AlertService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.alertService.getMessage().subscribe((message) => {
      let prevMessage = this.messages.findIndex(
        msg =>
          message != undefined &&
          message != null &&
          message != '' &&
          msg.text == message.text &&
          msg.type == message.type
      );
      if(message != undefined && message != null && message != '' && prevMessage < 0){
        this.messages.push(message);
      }
    });
  }

  close(message) {
    this.messages = this.messages.filter((x) => x !== message);
  }

}
