<div class="header">

  <div class="logo" style="margin-left: 0px;">
    <a href="https://autoresponderclient.vioniko.com/"><img src="https://vioniko.com/images/logo2.png" alt="">
    </a>
  </div>
  <div class="headerinner" style="margin-left: 260px;">
    <ul class="headmenu">

      <li class="right">
      <div class="userloggedinfo">
        <img src="https://vioniko.com/images/avatars/{{getOwnerId()}}.jpg" height="100px" width="100px">                      <div class="userinfo">
        <h5>{{getOwnerName()}}<small> - {{getOwnerEmail()}}</small></h5>
        <!--<ul>
          <li><a href="modificar_perfil.php">Editar Perfil</a></li>
          <li><a href="modificar_metas.php">Editar Metas</a></li>
          <li><a href="logout.php">Cerrar Sesi?n</a></li>
        </ul>-->
      </div>
      </div>
    </li>
    </ul><!--headmenu-->
    <!--headmenu-->
  </div>
</div>
