<!--Header Section Start-->
<!--<header id="header" class="header">
  <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid col-lg-12">
      <div class="navbar-header">
                <span>
                    <a class="navbar-brand fadeout-logo" href="/">
                        <div class="logo-flex">
                            <img class="lg-logo" alt="InviteManager" src="{{ staticImgPath }}logo2.png" />
                        </div>
                    </a>
                </span>
      </div>
    </div>
  </nav>
</header>-->

<!--Header Section End-->

<!--show loader until get the response from API-->
<img *ngIf="showPageLoader" src="{{ staticImgPath }}rolling.gif" class="loader" alt="" />

<div class="container" *ngIf="showError && !showPageLoader">
  <div class="mainContainer vertical-align">
    <div class="row">
      <div class="col-12 text-center mt-5 mb-4">
        <p style="color: #f00000;">Error: {{ errorMsg }}</p>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="subscriptionStatusChanged && false">
  <div class="mainContainer vertical-align">
    <div class="row">
      <div class="col-12 text-center mt-5 mb-4">
        <p style="color: green;">Success:  Subscription status changed successfully!</p>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="!showPageLoader && !showError">
  <div class="mainContainer vertical-align">
    <div class="row">
      <div class="col-12 text-center">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 213.983 141.99"
          style="enable-background: new 0 0 213.983 141.99;"
          [ngClass]="{ active: subscription_status == 1 }"
          xml:space="preserve"
        >
                    <path
                      style="fill-rule: evenodd; clip-rule: evenodd;"
                      d="M107.5,0c31.992,0,63.983-0.002,95.974,0.001
	c8.11,0.001,10.505,2.325,10.506,10.217c0.004,40.49,0.005,80.979-0.001,121.468c-0.002,7.805-2.556,10.301-10.522,10.301
	c-64.315,0.004-128.632,0.004-192.948,0c-7.954,0-10.503-2.5-10.504-10.318C-0.002,91.179-0.001,50.69,0.003,10.201
	c0-7.726,2.425-10.197,10.022-10.199C42.517-0.004,75.009,0,107.5,0z M8.236,13.25c-0.126,1.54-0.22,2.153-0.22,2.766
	C8.008,54.164,8.084,92.312,7.901,130.458c-0.02,4.222,2.253,3.526,4.693,3.526c62.969-0.002,125.938-0.042,188.907,0.097
	c3.888,0.009,4.57-1.142,4.558-4.733c-0.134-37.147-0.076-74.296-0.107-111.445c-0.001-1.247,0.46-2.613-0.512-3.861
	c-1.959,0.153-3.23,1.578-4.69,2.545c-28.307,18.743-56.639,37.449-84.841,56.351c-6.226,4.173-11.808,4.211-17.926-0.063
	c-8.593-6.002-17.425-11.662-26.162-17.458C50.893,41.533,29.962,27.658,8.236,13.25z M15.658,8.168
	c1.165,0.994,1.596,1.446,2.104,1.783c28.424,18.872,56.875,37.702,85.253,56.643c3.214,2.145,5.648,1.496,8.599-0.472
	c27.691-18.472,55.456-36.833,83.183-55.25c1.001-0.665,2.341-1.073,2.899-2.703C137.148,8.168,76.907,8.168,15.658,8.168z"
                    />
                </svg>
        <h2>
          You are
          <span *ngIf="subscription_status == 1">subscribed</span>
          <span *ngIf="subscription_status == 2">unsubscribed</span>
        </h2>
      </div>
      <div class="col-12 text-center">
        <div class="d-inline-block">
          <div class="userImg">
            <img
              src="https://www.gravatar.com/avatar/{{ userEmail }}?d={{ defaultImg }}"
              alt="User Profile Image"
            />
          </div>
          <!-- <a href="javascript:void(0)">{{ userData.email }}</a> -->
        </div>
      </div>
      <div class="col-12 text-center mt-5 mb-4">
        <p>
          You will <span *ngIf="subscription_status == 2">not</span> receive
          emails from us on behalf of
          Vioniko.
        </p>
      </div>
      <!-- unclickable: showButtonLoader -->
      <div class="col-12 text-center">
        <a
          href="javascript:void(0)"
          (click)="postSubscription(subscription_status_flip, true)"
          [ngClass]="{
                        resubscribe: subscription_status == 2,
                        unsubscribe: subscription_status == 1
                    }"
        >
          <span *ngIf="subscription_status == 1">Unsubscribe</span>
          <span *ngIf="subscription_status == 2">Resubscribe</span>
        </a>
      </div>
      <div class="col-12 text-center mt-5 mb-4" *ngIf="showError">
        <p style="color: #f00000;">Error: {{ errorMsg }}</p>
      </div>
      <div class="col-12 text-center mt-5 mb-4" *ngIf="subscriptionStatusChanged">
        <p style="color: green;">Success:  Subscription status changed successfully!</p>
      </div>
    </div>
  </div>
</div>
