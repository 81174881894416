import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const tokenData = JSON.parse(localStorage.getItem('currentUser'));
let httpOptions = { headers: null };
if (tokenData !== null) {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'q=0.8;application/json;q=0.9',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      cache: 'false',
      Authorization: 'Bearer ' + tokenData.user_jwtToken,
    }),
  };
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private apiUrl: string = environment.apiUrl;
  private showHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showHeader$ = this.showHeader.asObservable();
  private showHeaderOptions: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public showHeaderOptions$ = this.showHeaderOptions.asObservable();
  isEventDetail: boolean;

  constructor(private http: HttpClient) {
    this.toggleHeaderOn(true);
  }
  // check string convert to parse
  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  toggleHeaderOn(show: boolean) {
    this.showHeader.next(show);
  }

  toggleHeaderOptions(options) {
    this.showHeaderOptions.next(options);
  }

  /**
   * This function will be used globally to store data on redis and share it's key to TMS to get data for data communication.
   * @param data JSON object developer want to store in Redis(Cache DB)
   * @param key alphanumeric key used to store data against that key, and data is fetched from that key
   * @param httpOptions mandatory http params for POST request
   */
  postDataToRedis(data: any, key: string, httpOptions: any): Observable<any> {
    let url = this.apiUrl + `/api/datastore`;
    if (key) {
      url = url + '?key=' + key;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error))
    );
  }

  postSubscription(obj) {
    let url = this.apiUrl + '/api/users/email_subscription';
    console.log("obj inside service: ", obj);
    console.log("httpOptions: ", httpOptions);
    return this.http.put(url, obj, httpOptions).pipe(
      map((response: any) => {
        console.log('JSON Response::', response);
        return response.data;
      }),
      catchError((error: any) => observableThrowError(error))
    );
  }

}
