import { Injectable, EventEmitter, Output } from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;
  private timer = -1;
  @Output() message: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {
    // clear alert messages on route change
    router.events.subscribe((event) => {
      if(event instanceof NavigationStart) {
        if(this.keepAfterNavigationChange){
          // only keep for single location change
          this.keepAfterNavigationChange = false;
        }else{
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(
    message: string,
    keepAfterNavigationChange = false,
    timer = -1,
    showCloseButton = true,
    style: object = null
  ){
    this.timer = timer;
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: 'success',
      text: message,
      showCloseButton: showCloseButton,
      style: style,
    });
    this.autoClose();
  }

  error(
    message: string,
    keepAfterNavigationChange = false,
    timer = -1,
    showCloseButton = true,
    style: object = null
  ){
    this.timer = timer;
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: 'error',
      text: message,
      showCloseButton: showCloseButton,
      style: style
    });
    this.autoClose();
  }

  info(
    message: string,
    keepAfterNavigationChange = false,
    timer = -1,
    showCloseButton = true,
    style: object = null
  ){
    this.timer = timer;
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: 'info',
      text: message,
      showCloseButton: showCloseButton,
      style: style
    });
    this.autoClose();
  }

  warning(
    message: string,
    keepAfterNavigationChange = false,
    timer = -1,
    showCloseButton = true,
    style: object = null
  ){
    this.timer = timer;
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({
      type: 'warning',
      text: message,
      showCloseButton: showCloseButton,
      style: style
    });
    this.autoClose();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  close(){
    this.message.emit(null);
  }

  getEmittedValue() {
    return this.message;
  }

  autoClose() {
    if(this.timer > -1) {
      let timer = this.timer;
      setTimeout(() => {
        this.close();
      }, timer);
    }
  }
}
