import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { UserService } from './../../user/user.service';
// import { TemplateService } from '../../template/template.service';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash-es';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  email = '';
  name = '';
  constructor() { }

  ngOnInit(): void {
  }

  getOwnerId(){
    console.log("here inside getOwnerId");
    let ownerID = localStorage.getItem('owner_id');
    return ownerID;
  }

  getOwnerName(){
    let ownerName = localStorage.getItem('owner_name');
    return ownerName;
  }

  getOwnerEmail(){
    let ownerEmail = localStorage.getItem('owner_email');
    return ownerEmail;
  }

}
