import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../common.service';
// import { TemplateService } from '../../template/template.service';
// import { Md5 } from 'ts-md5/dist/md5';
// import { constants } from '../config/constants';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  showPageLoader: boolean = true;
  showButtonLoader: boolean = false;
  showError: boolean = false;
  errorMsg: string = '';
  token: string = '';
  subscription_status: string = '';
  subscription_status_flip: string = '';
  userData: any = {};
  staticImgPath: string = environment.staticImgPath;
  userEmail: any = '';
  subscriptionStatusChanged: boolean = false;
  // constants: any = constants;
  defaultImg = encodeURI("https://app1.spotlighttms.com/app/assets/images/user-profile-placeholder.png");
  constructor(private router: ActivatedRoute, private commonService: CommonService, private route: Router) {}

  ngOnInit() {
    this.token = this.router.snapshot.params['token'];
    this.subscription_status = this.router.snapshot.params['subscription_status'];
    this.subscription_status_flip = this.subscription_status == '1' ? '2' : '1';
    console.log("this.token", this.token);
    this.getSubscription();
    this.postSubscription(this.subscription_status);
  }

  getSubscription() {
    this.userData.subscription_status_id = 2;
    /*this.templateService.getSubscription(this.token).subscribe(
      (data) => {
        this.showError = false;
        this.userData = data;
        // this.userEmail = Md5.hashStr(this.userData.email);
        this.showPageLoader = false;
      },
      (error) => {
        this.showPageLoader = false;
        this.showError = true;
        this.errorMsg = error.hasOwnProperty('msg') ? error.msg : error.code_name;
      }
    );*/
    this.showPageLoader = false;
  }

  postSubscription(status_id, btn_clicked = false) {
    this.showButtonLoader = true;
    this.showPageLoader = false;
    // this.userData.subscription_status_id = 2;
    // let status_id =
      // this.userData.subscription_status_id == constants.SUBSCRIBED
        // ? constants.UNSUBSCRIBED
        // : constants.SUBSCRIBED;
        let obj = {
          subscription_status_id: status_id,
          prospect_id: this.token
        };
        console.log("obj: ", obj);
    this.commonService.postSubscription(obj).subscribe(
      (data) => {
        this.showError = false;
        this.userData = data;
        // this.userEmail = Md5.hashStr(this.userData.email);
        this.showButtonLoader = false;
        this.showPageLoader = false;
        if(btn_clicked){
          console.log('value true found');
          this.subscriptionStatusChanged = true;
          setTimeout(() => {
            console.log('hereeeee');
            // this.route.navigate(['/subscription/'+this.token+'/'+status_id]);
            window.location.href = '/subscription/'+this.token+'/'+status_id;
          }, 2000);
        }
      },
      (error) => {
        this.showButtonLoader = false;
        this.showPageLoader = false;
        this.showError = true;
        this.subscriptionStatusChanged = false;
        this.errorMsg = error.hasOwnProperty('msg') ? error.msg : error.code_name;
      }
    );
  }
}
