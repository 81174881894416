<ng-container *ngIf="messages.length > 0">
  <div class="custom-alert">
    <div
      *ngFor="let message of messages; let index = index"
      [ngClass]="{
                'alert-success': message.type === 'success',
                'alert-danger': message.type === 'error',
                invisible: message.type === 'close',
                'alert-info': message.type == 'info',
                'alert-warning': message.type == 'warning'
            }"
      tabindex="0"
      [ngStyle]="message.style"
      [style.top.px]="index * 50"
      class="alert alert-dismissable"
    >
      <a
        href="javascript:;"
        *ngIf="message.showCloseButton && message.text != null && message.text != ''"
        class="close"
        aria-label="close"
        (click)="close(message)"
        tabindex="0"
      >&times;</a
      >
      <!--Using this for end proxy session-->
      <a
        href="/sudoSessions/endSudoSession?act=endSession"
        *ngIf="message.showProxySession"
        class="close"
        aria-label="close"
        [ngStyle]="message.style"
        style="font-weight: 700 !important; opacity: 1; text-shadow: none"
        tabindex="0"
      ><i class="fas fa-stop-circle"></i> END SESSION</a
      >
      <div *ngIf="message.text != null && message.text != ''" [innerHTML]="convertHtmlToText(message.text)"></div>
    </div>
  </div>
</ng-container>
